var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "firstTabForm",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Order Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "order-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "order-name",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.batchName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchName", $$v)
                              },
                              expression: "form.batchName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Order No.:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "order-number"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "order-number",
                              placeholder: "Automatic system generation",
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Patch Type:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "patch-type",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(_vm.types.patchType, function(label, index) {
                              return _c(
                                "b-form-checkbox",
                                {
                                  key: index,
                                  staticClass: "mr-2 mt-2",
                                  attrs: { required: true, value: label.id },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeDeviceId(label.id)
                                    }
                                  },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type"
                                  }
                                },
                                [_vm._v(" " + _vm._s(label.typeName) + " ")]
                              )
                            }),
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Device Model:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "device-model",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "device-model",
                              options: _vm.types.deviceModel,
                              "value-field": "id",
                              "text-field": "modelName",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.deviceId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deviceId", $$v)
                              },
                              expression: "form.deviceId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Total Boxes:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "total-boxes",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "total-boxes",
                              type: "number",
                              min: "0",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.totalBoxes,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "totalBoxes", $$v)
                              },
                              expression: "form.totalBoxes"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Number of boxes per box:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "number-of-boxes-per-box",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "total-boxes",
                              type: "number",
                              min: "0",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.quantityPackingBox,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "quantityPackingBox", $$v)
                              },
                              expression: "form.quantityPackingBox"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Remarks:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "remarks"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remarks",
                              placeholder: "Other Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { lg: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }