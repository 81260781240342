<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- 订单名称 -->
            <b-form-group
              label="Order Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="order-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="order-name"
                v-model="form.batchName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please enter"
              ></b-form-input>
            </b-form-group>

            <!-- 订单号 -->
            <b-form-group
              label="Order No.:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="order-number"
            >
              <b-form-input
                id="order-number"
                placeholder="Automatic system generation"
                disabled
              >
              </b-form-input>
            </b-form-group>
            <!-- 贴片类型 -->
            <b-form-group
              label="Patch Type:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="patch-type"
              label-class="requiredRow"
            >
              <div class="d-flex flex-wrap">
                <b-form-checkbox
                  v-for="(label, index) in types.patchType"
                  :required="true"
                  :key="index"
                  v-model="form.type"
                  :value="label.id"
                  class="mr-2 mt-2"
                  @change="changeDeviceId(label.id)"
                >
                  {{ label.typeName }}
                </b-form-checkbox>
              </div>
            </b-form-group>

            <!-- 设备型号 -->
            <b-form-group
              label="Device Model:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="device-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="device-model"
                v-model="form.deviceId"
                :options="types.deviceModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>

            <!-- 生产总箱数 -->
            <b-form-group
              label="Total Boxes:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="total-boxes"
              label-class="requiredRow"
            >
              <b-form-input
                id="total-boxes"
                v-model="form.totalBoxes"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Number of boxes per box:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="number-of-boxes-per-box"
              label-class="requiredRow"
            >
              <b-form-input
                id="total-boxes"
                v-model="form.quantityPackingBox"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- 备注 -->
            <b-form-group
              label="Remarks:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="form.remarks"
                placeholder="Other Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" style="margin-left: 30px">Cancel</b-button>
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      title: "Patch Orders Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Patch Orders Create",
          active: true,
        },
      ],
      types: {
        patchType: [
          { typeName: "Disposable", id: 3 },
          { typeName: "Replaceable", id: 7 },
        ],
        deviceModel: [{ modelName: "Please select", id: "" }],
      },
      form: {
        batchName: "",
        type: 3,
        deviceId:"",
        totalBoxes:"",
        quantityPackingBox:"",
        remarks: "",
      },
    };
  },
  methods: {
    init() {
      this.$api.Dropdown.selectAllTestSmokeBombModel({
        type: 3
      }).then((res) => {
        if (res.success && res.data) {
          this.types.deviceModel =
            this.types.deviceModel.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.form.type) {
        this.$bvToast.toast("Please select Patch Type.");
        return;
      }
      this.$api.packingBoxOrder.packingBoxOrderSave(this.form).then((res) => {
        if (res.success) {
          this.$router.go(-1);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    changeDeviceId(deviceId){
      this.types.deviceModel = [{ modelName: "Please select", id: "" }];
      this.$api.Dropdown.selectAllTestSmokeBombModel({
        type: deviceId
      }).then((res) => {
        if (res.success && res.data) {
          this.types.deviceModel =
            this.types.deviceModel.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    onVoltagePlus(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value + 0.1).toFixed(1)
      );
    },
    onVoltageReduce(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value - 0.1).toFixed(1)
      );
    },
    onVoltagePlus1(index) {
      this.temperatureList1[index].value = parseFloat(
        (this.temperatureList1[index].value + 0.1).toFixed(1)
      );
    },
    onVoltageReduce1(index) {
      this.temperatureList1[index].value = parseFloat(
        (this.temperatureList1[index].value - 0.1).toFixed(1)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.required-icon {
  ::before {
    content: "*";
  }
}
</style>
